/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import banner from "../assets/banner.png";
import Search from "./Search";
import profile from "../assets/profile-avatar.png";
import { AiOutlineSearch } from "react-icons/ai";
import { useAuth } from "../components/utility/AuthProvider";
import { RiLockPasswordLine, RiLogoutBoxRLine } from "react-icons/ri";
import { CiMenuBurger } from "react-icons/ci";
import { BsChevronDown } from "react-icons/bs";
import ChangePasswordDialog from "./ChangePasswordDialog";

const TOP_OFFSET = 66;

const menuItems = [
  {
    id: "home",
    title: "Home",
    path: "/home",
  },
  {
    id: "explore",
    title: "Explore",
    path: "/explore",
  },
  {
    id: "my_list",
    title: "My List",
    path: "/my-list",
  },
  {
    id: "account",
    title: "Account",
    path: "/account",
  },
];

const Navbar = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);

  const toggleMobileMenu = useCallback(() => {
    setShowMobileMenu((current) => !current);
  });

  const toggleAccountMenu = useCallback(() => {
    setShowAccountMenu((current) => !current);
  });

  const {
    isUserSignedIn,
    setIsUserSignedIn,
    setUserId,
    userDetails,
    hideNavbar,
    searchMovieList,
  } = useAuth();

  const handleLogout = () => {
    window.localStorage.removeItem("isLoggedIn");
    window.localStorage.removeItem("userId");
    setIsUserSignedIn(false);
    setUserId(null);
    setLoggedIn(false);
    navigate("/login", { replace: true });
  };

  const goToSignInPage = () => {
    navigate("/login");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={
          hideNavbar
            ? `hidden`
            : `flex flex-row items-center justify-between px-4 py-6 md:px-16 z-[40] w-full fixed top-0 transition duration-500 ease-in-out ${
                isScrolled ? "bg-black" : "bg-gradient-to-b from-black"
              }`
        }
      >
        <div className="flex items-center space-x-4">
          {isUserSignedIn ? (
            <div
              onClick={toggleMobileMenu}
              className="lg:hidden flex flex-row items-center gap-2 cursor-pointer relative"
            >
              <CiMenuBurger
                className={`text-white transition ${
                  showMobileMenu ? "rotate-180" : "rotate-0"
                }`}
              />
              <MobileMenu visible={showMobileMenu} />
            </div>
          ) : null}
          <img
            onClick={() => {
              if (isUserSignedIn) {
                navigate("/home");
              } else {
                navigate("/");
              }
            }}
            src={banner}
            width={200}
            height={200}
            alt="WATCHDAT"
            className="object-contain h-12 lg:h-16 hover:cursor-pointer"
          />
        </div>
        {isUserSignedIn ? (
          <div className="hidden lg:flex flex-grow">
            <div className="flex justify-end flex-row items-center space-x-4 pl-5">
              <NavbarItem />
            </div>
          </div>
        ) : null}
        <div className="flex flex-row ml-auto gap-7 items-center">
          <div className="text-gray-200 hover:text-gray-300 cursor-pointer transition">
            <div className="flex items-center space-x-4">
              {isUserSignedIn ? (
                <>
                  <div>
                    {showSearchBar ? (
                      <Search
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        showSearchBar={showSearchBar}
                        setShowSearchBar={setShowSearchBar}
                        movieList={searchMovieList}
                        setSearchResults={setSearchResults}
                      />
                    ) : (
                      <AiOutlineSearch
                        onClick={() => setShowSearchBar(!showSearchBar)}
                        className="hidden sm:inline sm:h-6 cursor-pointer h-8 w-8 text-white"
                      />
                    )}
                  </div>
                  <div>
                    {showSearchBar && searchResults.length > 0 && (
                      <div className="absolute top-full left-0 bg-white shadow-md p-2 mt-2 rounded-md w-full">
                        <ul>
                          {searchResults.map((item) => (
                            <li key={item.id}>
                              <Link to={item.path}>{item.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={toggleAccountMenu}
                    className="flex items-center gap-2 cursor-pointer relative"
                  >
                    <div className="w-5 h-5 lg:w-12 lg:h-12 rounded-full overflow-hidden shadow-sm">
                      <img
                        src={profile}
                        alt="User Avatar"
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <BsChevronDown
                      className={`text-white transition-transform duration-200 ${
                        showAccountMenu ? "rotate-180" : "rotate-0"
                      }`}
                    />
                    <AccountMenu
                      userDetails={userDetails}
                      userName={userDetails ? userDetails.name : "Username"}
                      navToUserAccount={() => {
                        navigate("/account");
                      }}
                      handleSignout={handleLogout}
                      setShowChangePasswordDialog={setShowChangePasswordDialog}
                      visible={showAccountMenu}
                    />
                  </div>
                </>
              ) : (
                <div className="flex flex-row">
                  <button
                    onClick={goToSignInPage}
                    className="bg-gradient-to-r from-green-700 to-green-900 hover:from-green-600 hover:to-green-800 px-5 py-2 rounded-lg flex items-center space-x-1 cursor-pointer text-white shadow-md transition-transform duration-300 ease-in-out hover:shadow-lg hover:scale-105"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <span className="font-medium">Sign In</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordDialog
        isDialogVisible={showChangePasswordDialog}
        setIsDialogVisible={setShowChangePasswordDialog}
        setLoggedIn={setLoggedIn}
      />
    </>
  );
};

export default Navbar;

const AccountMenu = ({
  userName,
  navToUserAccount,
  handleSignout,
  showChangePasswordDialog,
  setShowChangePasswordDialog,
  visible,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <>
      <div className="bg-black w-64 absolute top-16 right-4 py-6 flex flex-col border border-gray-700 rounded-lg shadow-xl">
        <div className="flex flex-col gap-4 px-4">
          <div onClick={navToUserAccount} className="flex items-center gap-3">
            <img
              className="w-10 h-10 rounded-full object-cover"
              src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Netflix-avatar.png"
              alt="User Avatar"
            />
            <p className="text-white text-base font-medium transition duration-150 ease-in-out group-hover:underline">
              {userName}
            </p>
          </div>
          <hr className="border-gray-600" />
          <button
            onClick={() =>
              setShowChangePasswordDialog(!showChangePasswordDialog)
            }
            className="flex items-center gap-2 px-3 py-2 text-white text-sm rounded-md transition duration-150 ease-in-out hover:bg-gray-800 hover:scale-105 transform"
          >
            <RiLockPasswordLine className="text-lg" />
            Change Password
          </button>
          <button
            onClick={handleSignout}
            className="flex items-center gap-2 px-3 py-2 text-white text-sm rounded-md transition duration-150 ease-in-out hover:bg-gray-800 hover:scale-105 transform"
          >
            <RiLogoutBoxRLine className="text-lg" />
            Sign out
          </button>
        </div>
      </div>
    </>
  );
};

const MobileMenu = ({ visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="bg-gray-900 w-64 absolute top-12 left-0 py-6 flex-col border border-gray-700 rounded-lg shadow-xl transition-transform duration-300 ease-in-out transform translate-x-0">
      <div className="flex flex-col gap-2">
        {menuItems.map((item, index) => (
          <div
            key={index}
            className="px-4 py-2 text-center text-white font-medium hover:bg-gray-700 hover:text-blue-400 rounded-md transition-colors duration-150 ease-in-out cursor-pointer"
          >
            <Link to={item.path} className="text-white">
              <div key={item.id}>{item.title}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

const NavbarItem = () => {
  return (
    <div className=" hover:text-gray-300 transition-all">
      <ul className="hidden md:space-x-4 md:flex cursor-pointer">
        {menuItems.map((item) => (
          <li
            className="cursor-pointer text-[16px] font-light text-[#e5e5e5] transition duration-[.4s] hover:text-[#b3b3b3]"
            key={item.id}
          >
            <Link to={item.path} className="text-white">
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
