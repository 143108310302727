/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaInfoCircle, FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ApiEndPoints from "./api/ApiEndPoints";
import { useAuth } from "./utility/AuthProvider";
import { Helmet } from "react-helmet";
import MovieDialog from "./MovieDialog";

const Main = () => {
  const [headerMovie, setHeaderMovie] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [movieIndex, setMovieIndex] = useState(fetchMovieID());
  const [movieDetails, setMovieDetails] = useState(null);

  const navigate = useNavigate();

  const endPoints = ApiEndPoints();

  const {
    setSelectedContent,
    setSearchMovieList,
    setUserDetails,
    setUserId,
    userId,
  } = useAuth();

  function fetchMovieID() {
    return Math.floor(Math.random() * headerMovie.length);
  }

  var displayMovie = headerMovie[movieIndex];

  function changeHeaderMovie() {
    setMovieIndex(fetchMovieID());
  }

  var fetchHeaderMovie = () => {
    fetch(endPoints.fetchAllContent)
      .then((response) => response.json())
      .then((data) => {
        setHeaderMovie(data.ContentList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var postRecentlyWatchedMovie = (content_id) => {
    fetch(endPoints.updateUserWatchedMovies + content_id)
      .then((response) => response.json())
      .then((data) => {});
  };

  const fetchMovieDetails = () => {
    fetch(endPoints.fetchContentById + displayMovie.Content.content_id)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setMovieDetails(data);
        setIsDialogVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var fetchPlayMovieDetails = () => {
    if (displayMovie.Content.genre_id === 15) {
      fetchMovieDetails();
    } else {
      fetch(endPoints.fetchContentById + displayMovie.Content.content_id)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setSelectedContent({
            contentUrl: data.videoUrls[0],
            contentTitle: data.Content.title,
            contentId: data.Content.content_id,
          });
          postRecentlyWatchedMovie(data.Content.content_id);
          navigate("/movie-player");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  // Refactored fetchMovieList to use async/await
  const fetchMovieList = async () => {
    try {
      const response = await fetch(endPoints.fetchAllContent);
      const data = await response.json();
      setSearchMovieList(data.ContentList);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Refactored fetchAccountDetails to use async/await
  const fetchAccountDetails = async () => {
    try {
      const response = await fetch(endPoints.fetchUserData);
      const data = await response.json();
      setUserDetails(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const isLoggedIn = window.localStorage.getItem("isLoggedIn") === "true";
    const localUserId = window.localStorage.getItem("userId");

    if (isLoggedIn && localUserId) {
      setUserId(localUserId); // Schedule the update of userId
    }
  }, []); // This effect is only concerned with initializing the userId from localStorage

  useEffect(() => {
    // This effect depends on userId, so it runs after userId is set
    if (userId) {
      fetchAccountDetails(); // Now dynamically constructs the URL with the updated userId
      fetchMovieList();
      fetchHeaderMovie();
    }
  }, [userId]); // Re-run this effect whenever userId changes

  useEffect(() => {
    const internalID = setInterval(() => {
      changeHeaderMovie();
    }, 10000);
    return () => clearInterval(internalID);
  });

  const truncateString = (string, num) => {
    if (string?.length > num) {
      return `${string.slice(0, num)} ${"..."}`;
    } else {
      return string;
    }
  };

  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7248466627372160"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="w-full h-screen text-white overflow-hidden relative">
        <div className="w-full h-full">
          <img
            className="w-full h-full object-cover"
            src={`${displayMovie?.imageUrls[0]}`}
            alt={`${displayMovie?.Content.title}`}
          />
          <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black via-black to-transparent"></div>
          <div className="absolute bottom-0 left-0 p-4 pb-10 md:p-16">
            <h1 className="text-lg sm:text-xl md:text-3xl lg:text-4xl font-bold drop-shadow-md">
              {displayMovie?.Content.title}
            </h1>
            <p className="mt-3 md:mt-8 text-xs sm:text-sm md:text-lg w-11/12 md:w-3/4 lg:w-1/2 drop-shadow-xl">
              {truncateString(displayMovie?.Content.description, 200)}
            </p>
            <div className="mt-6 flex flex-row gap-2 sm:gap-4">
              <button
                onClick={fetchPlayMovieDetails}
                className="bg-white text-black hover:bg-gray-200 focus:ring-4 ring-gray-300 rounded-md py-2 sm:py-3 px-3 sm:px-4 text-xs sm:text-sm md:text-lg font-semibold transition duration-150 ease-in-out transform hover:-translate-y-1"
              >
                <FaPlay className="inline mr-1 sm:mr-2 text-lg" /> Play
              </button>
              <button
                onClick={fetchMovieDetails}
                className="bg-gray-600 hover:bg-gray-700 focus:ring-4 ring-gray-500 rounded-md py-2 sm:py-3 px-3 sm:px-4 text-xs sm:text-sm md:text-lg font-semibold transition duration-150 ease-in-out transform hover:-translate-y-1"
              >
                <FaInfoCircle className="inline mr-1 sm:mr-2 text-lg" /> More
                Info
              </button>
            </div>
          </div>
        </div>
      </div>

      <MovieDialog
        isDialogVisible={isDialogVisible}
        movieDetails={movieDetails}
        setIsDialogVisible={setIsDialogVisible}
      />
    </>
  );
};

export default Main;
