import { React, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useAuth } from "./utility/AuthProvider";
import MovieDialog from "./MovieDialog";
import SystemAlerts from "./SystemAlerts";

const SavedShows = ({ savedMovies, refetchSavedMovies, endPoints }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [movieDetails, setMovieDetails] = useState(null);

  const systemAlerts = SystemAlerts();

  var deleteSavedMovie = async (movieTitle, content_id) => {
    try {
      const response = await fetch(
        endPoints.removeMovieFromUserList + content_id
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      systemAlerts.deleteMovieFromList(movieTitle);
      refetchSavedMovies();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const { setSelectedContent } = useAuth();

  const fetchMovieDetails = (content_id) => {
    fetch(endPoints.fetchContentById + content_id)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSelectedContent({
          contentUrl: data.videoUrls[0],
          contentTitle: data.Content.title,
          contentId: data.Content.content_id,
        });
        setMovieDetails(data);
        setIsDialogVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div className="relative">
        <div
          id={"slider"}
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 p-4"
        >
          {savedMovies.map((item) => (
            <div
              onClick={() => fetchMovieDetails(item?.content_id)}
              key={item?.content_id}
              className="group cursor-pointer relative aspect-w-2 aspect-h-3"
            >
              <img
                className="w-full h-full object-cover rounded-lg transition-all duration-300 ease-in-out transform group-hover:scale-105"
                src={`${item?.imageUrls[0]}`}
                alt={item?.title}
              />
              <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex justify-center items-center rounded-lg">
                <div className="text-center px-2">
                  <p className="text-xs md:text-sm font-bold text-white">
                    {item?.title}
                  </p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteSavedMovie(item?.title, item.content_id);
                  }}
                  className="absolute text-gray-300 top-2 right-2 p-1 hover:bg-red-600 hover:text-white rounded-full transition-all duration-300 ease-in-out"
                >
                  <AiOutlineClose size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <MovieDialog
        isDialogVisible={isDialogVisible}
        movieDetails={movieDetails}
        setIsDialogVisible={setIsDialogVisible}
      />
    </>
  );
};

export default SavedShows;
