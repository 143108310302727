import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000000", // Black color for primary buttons
    },
    secondary: {
      main: "#000000", // Black color for secondary buttons
    },
    text: {
      primary: "#ffffff", // White color for button text
    },
  },
});

function InActiveAccountDialog({
  accountEmail,
  open,
  onClose,
  setChosenPackageType,
  setUserDetails,
  navigate,
}) {
  const handleSelect = (packageType) => {
    onClose();

    if (packageType === 1) {
      setUserDetails(accountEmail);
      setChosenPackageType(1);
      navigate("/card-details");
    } else if (packageType === 2) {
      setUserDetails(accountEmail);
      setChosenPackageType(2);
      navigate("/card-details");
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Typography variant="h6" align="center" gutterBottom>
            Attention
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" align="center" paragraph>
            Your account is inactive. Please activate your account to continue
            using our services.
          </Typography>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mt={2}
            mb={2}
            px={2}
          >
            <Box textAlign="center">
              <Typography variant="subtitle1" gutterBottom>
                With Ads
              </Typography>
              <Typography variant="body2" gutterBottom>
                $4.99 USD
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSelect(1)}
                size="small"
              >
                Select
              </Button>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box textAlign="center">
              <Typography variant="subtitle1" gutterBottom>
                Ad Free
              </Typography>
              <Typography variant="body2" gutterBottom>
                $10.99 USD
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSelect(2)}
                size="small"
              >
                Select
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Activate Later
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default InActiveAccountDialog;
