import React, { useEffect } from "react";
import { useAuth } from "../components/utility/AuthProvider";
import VideoPlayer from "../components/VideoPlayer";

function MovieScreen() {
  const { selectedContent } = useAuth();

  return (
    <div className="w-full h-screen text-white">
      <VideoPlayer
        videoUrl={selectedContent.contentUrl}
        title={selectedContent.contentTitle}
        contentId={selectedContent.contentId}
      />
    </div>
  );
}

export default MovieScreen;
