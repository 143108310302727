import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import hero from "../assets/hero.jpg";
import { useAuth } from "../components/utility/AuthProvider";
import ApiEndPoints from "../components/api/ApiEndPoints";
import InputField from "../components/utility/InputField";
import SystemAlerts from "../components/SystemAlerts";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    setUserDetails,
    hideNavbar,
    hideFooter,
    setHideNavbar,
    setHideFooter,
  } = useAuth();

  const navigate = useNavigate();
  const systemAlerts = SystemAlerts();
  const endPoints = ApiEndPoints();

  // SIGN UP WITH FREE TRAIL
  var freeTrailBasedSignUp = () => {
    fetch(endPoints.postCreateUserFreeTrail, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: `${firstName} ${lastName}`,
        email: `${email}`,
        phoneNumber: `${phoneNumber}`,
        password: `${password}`,
        country: `${country}`,
        package_type: `2`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          systemAlerts.freeTrailUserCreatedAlert();
          navigate("/login");
        } else {
          systemAlerts.failedUserCreationAlert();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getCountry = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
          )
            .then((response) => response.json())
            .then((data) => {
              const countryName = data.address.country;
              setCountry(countryName);
            })
            .catch((error) => {
              console.error("Error fetching country:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  // SIGN UP WITHOUT FREE TRAIL
  var subscriptionBasedSignUp = () => {
    fetch(endPoints.postCreateUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: `${firstName} ${lastName}`,
        email: `${email}`,
        phoneNumber: `${phoneNumber}`,
        password: `${password}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setUserDetails(`${email}`);
          systemAlerts.userCreatedAlert();
          navigate("/card-details");
        } else {
          systemAlerts.failedUserCreationAlert();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if first name and last name are not empty
    if (!firstName || !lastName) {
      systemAlerts.validationError("First Name and Last Name cannot be empty.");
      return;
    }

    // Check if the email address is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      systemAlerts.validationError("Please enter a valid email address.");
      return;
    }

    // Check if the password and confirm passwords are the same
    if (password !== confirmPassword) {
      systemAlerts.validationError(
        "Password and Confirm Password must be the same."
      );
      return;
    }

    try {
      // subscriptionBasedSignUp();
      freeTrailBasedSignUp();
    } catch (error) {
      systemAlerts.validationError(
        "Unsuccessful Registration!\r\n",
        `${error}`
      );
    }
  };

  useEffect(() => {
    console.clear();
    if (hideNavbar || hideFooter) {
      setHideNavbar(false);
      setHideFooter(false);
    }
  }, []);

  return (
    <>
      <div
        className="bg-no-repeat bg-fixed bg-cover bg-center text-white w-full h-screen"
        style={{
          backgroundImage: `url(${hero})`,
          backdropFilter: "blur(50px)",
        }}
      >
        <div className="bg-black/80 w-full h-full flex justify-center items-center px-4">
          <div className="w-full max-w-md px-6 py-8 mt-5 bg-black/75 text-white rounded-lg shadow-lg">
            <h1 className="text-2xl sm:text-3xl font-bold mb-8 text-center">
              Create Your Account
            </h1>
            <form onSubmit={handleSubmit} className="space-y-6">
              <fieldset className="space-y-4">
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                  <InputField
                    id="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    placeholder="First Name"
                    required={true}
                  />
                  <InputField
                    id="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    placeholder="Last Name"
                    required={true}
                  />
                </div>
              </fieldset>
              <fieldset className="space-y-4">
                <InputField
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                  autoComplete="email"
                  required={true}
                />
                <InputField
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="text"
                  placeholder="Phone Number"
                  required={true}
                />
              </fieldset>
              <fieldset className="space-y-4">
                <InputField
                  id="country"
                  value={country}
                  type="text"
                  placeholder="Country"
                  readOnly
                />
                <button
                  type="button"
                  onClick={getCountry}
                  className="w-full py-3 rounded bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 transition-all text-white font-bold"
                >
                  Confirm Country
                </button>
              </fieldset>
              <fieldset className="space-y-4">
                <div className="relative">
                  <InputField
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    autoComplete="current-password"
                    required={true}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </div>
                <div className="relative">
                  <InputField
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    autoComplete="current-password"
                    required={true}
                  />
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  >
                    {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </div>
              </fieldset>
              <button
                type="submit"
                className="w-full py-3 rounded bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 transition-all text-white font-bold"
              >
                Sign Up
              </button>
              <div className="text-center text-sm text-gray-400 mt-4">
                Already have an account?{" "}
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="cursor-pointer text-white hover:text-green-500 transition-colors"
                >
                  Click here
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
