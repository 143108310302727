/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as cardValidator from "card-validator";
import { useNavigate } from "react-router-dom";
import hero from "../assets/hero.jpg";
import visa from "../assets/visa.png";
import mastercard from "../assets/mastercard.png";
import ApiEndPoints from "../components/api/ApiEndPoints";
import { useAuth } from "../components/utility/AuthProvider";
import InputField from "../components/utility/InputField";
import SystemAlerts from "../components/SystemAlerts";

const PaymentDetails = () => {
  const {
    userDetails,
    setUserDetails,
    chosenPackageType,
    setChosenPackageType,
  } = useAuth();

  const packageType = {
    1: ["Package One", "$4.99 USD"],
    2: ["Package Two", "$10.99 USD"],
  };

  const [cardInfo, setCardInfo] = useState({
    cardNumber: "",
    cardHolderName: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    expiryDate: "",
    expiryMonth: "",
    expiryYear: "",
    cvv: "",
  });

  const [iframeLink, setIframeLink] = useState(null);
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [didInitiatePayment, setDidInitiatePayment] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);

  const navigate = useNavigate();
  const endPoints = ApiEndPoints();
  const systemAlerts = SystemAlerts();

  const handleInputChange = (e) => {
    setCardInfo({ ...cardInfo, [e.target.name]: e.target.value });
  };

  var postCompletePayment = () => {
    if (!isPaymentComplete && transactionId !== null) {
      fetch(endPoints.postCompletePayment, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transaction_id: transactionId,
          email: `${userDetails}`,
          package_type: `${chosenPackageType}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.status === 200) {
            setIsPaymentComplete(true);
            setShowIframePopup(false);

            setUserDetails(null);
            setChosenPackageType(0);

            systemAlerts.successfulPaymentAlert();

            navigate("/login");
          } else if (showIframePopup) {
            setShowIframePopup(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  var postInititalPayment = () => {
    fetch(endPoints.postInitiatePayment, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sale: true,
        email: `${userDetails}`,
        phone_number: `${cardInfo.phoneNumber}`,
        address_line_1: `${cardInfo.addressLine1}`,
        address_line_2: `${cardInfo.addressLine2}`,
        city: `${cardInfo.city}`,
        contact_person: `${cardInfo.cardHolderName}`,
        package_type: `${chosenPackageType}`,
        card_number: `${cardInfo.cardNumber}`,
        card_expiration: `${cardInfo.expiryMonth}/${cardInfo.expiryYear.slice(
          -2
        )}`,
        cvv: parseInt(cardInfo.cvv, 10),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "200") {
          setIframeLink(data.file_url);
          setDidInitiatePayment(true);
          setShowIframePopup(true);
          setTransactionId(data.transaction_id);
        } else {
          systemAlerts.validationError("Error Initializing Payment...");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Helper function to check if all required fields are filled
    const areRequiredFieldsFilled = () => {
      const requiredFields = [
        "cardNumber",
        "cardHolderName",
        "addressLine1",
        "city",
        "expiryMonth",
        "expiryYear",
        "cvv",
      ];
      return requiredFields.every((field) => cardInfo[field]);
    };

    // Helper function for card expiry validation
    const isCardExpired = (month, year) => {
      const expiryDate = new Date(year, month - 1); // Month is 0-indexed
      return expiryDate < new Date();
    };

    if (!didInitiatePayment) {
      if (!areRequiredFieldsFilled()) {
        systemAlerts.validationError("Please fill in all required fields.");
        return;
      }

      const { isValid, card } = cardValidator.number(cardInfo.cardNumber);
      if (!isValid) {
        systemAlerts.validationError("Invalid card number.");
        return;
      }

      let { expiryMonth, expiryYear, cvv } = cardInfo;
      if (expiryYear.length === 2) {
        expiryYear = `20${expiryYear}`;
      }

      if (expiryMonth.length !== 2 || expiryYear.length !== 4) {
        systemAlerts.validationError(
          "Expiry month and year must be MM/YY format."
        );
        return;
      }

      if (isCardExpired(expiryMonth, expiryYear)) {
        systemAlerts.validationError("Card is expired.");
        return;
      }

      const cvvLength = card ? card.code.size : 3;
      if (cvv.length !== cvvLength) {
        systemAlerts.validationError(`CVV must be ${cvvLength} digits.`);
        return;
      }

      const cvvAsInt = parseInt(cvv, 10);
      if (isNaN(cvvAsInt)) {
        systemAlerts.validationError("Invalid CVV.");
        return;
      }

      setCardInfo({
        ...cardInfo,
        expiryYear,
        expiryMonth,
        // cvv is already a string, no need to convert to int and back
      });

      postInititalPayment();
    } else if (!isPaymentComplete) {
      postCompletePayment();
    }
  };

  useEffect(() => {
    console.clear();
    if (userDetails === null || chosenPackageType === 0) {
      navigate("/signup");
    }
  }, []);

  return (
    <>
      {iframeLink && showIframePopup && (
        <div className="fixed top-5 left-0 right-0 bottom-0 z-50 bg-gray-700 bg-opacity-50">
          <div className="max-w-[550px] h-auto mx-auto mt-20 bg-white rounded shadow-lg overflow-auto relative">
            <iframe
              src={iframeLink}
              className="w-full px-4 py-4"
              title="Payment confirmation"
              onLoad={() => {
                setTimeout(async () => {
                  if (!isPaymentComplete) {
                    postCompletePayment();
                    // signUpUser();
                  }
                }, 5000);
              }}
            ></iframe>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
            </div>
          </div>
        </div>
      )}
      <div
        className="flex justify-center items-center bg-no-repeat bg-fixed bg-cover bg-center text-white w-full min-h-screen"
        style={{
          backgroundImage: `url(${hero})`,
          backdropFilter: "blur(50px)",
        }}
      >
        {/* Use Tailwind's container classes to control max-width at different breakpoints */}
        <div className="bg-black/75 w-full h-full overflow-auto p-4 md:p-20">
          {/* Improve responsiveness by adjusting padding and max-width on larger screens */}
          <div className="container mx-auto max-w-md mt-10 md:max-w-lg p-4 md:p-8 rounded-lg shadow-xl bg-black/80 backdrop-blur-md">
            <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 md:p-6 rounded-lg shadow-xl text-white">
              <div className="text-center mb-4">
                <h1 className="text-2xl md:text-3xl font-extrabold pb-2">
                  Payment Details
                </h1>
                <p className="text-sm md:text-md font-light">
                  Complete your secure payment for account:
                </p>
                <p className="text-sm md:text-md font-light">{userDetails}</p>
              </div>
              <div className="bg-black bg-opacity-20 p-3 rounded-lg mb-4 flex flex-col justify-center items-center text-center">
                <h2 className="text-md md:text-lg font-semibold">
                  Content Plan: {packageType[chosenPackageType][0]}
                </h2>
                <p>{packageType[chosenPackageType][1]}</p>
              </div>
              <div className="text-center flex justify-center">
                <h3 className="text-md md:text-lg font-semibold px-2">
                  We Accept
                </h3>
                <div className="flex justify-center items-center gap-2">
                  <div className="inline-flex items-center justify-center bg-white rounded-md p-1">
                    <img className="h-4 md:h-5" src={visa} alt="Visa" />
                  </div>
                  <div className="inline-flex items-center justify-center bg-white rounded-md p-1">
                    <img
                      className="h-4 md:h-5"
                      src={mastercard}
                      alt="MasterCard"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <p className="text-xs text-gray-400 mt-2">
                  All transactions are secure and encrypted.
                </p>
              </div>
            </div>
            {/* Adjust form field sizes and spacing for better mobile appearance */}
            <form
              onSubmit={handleSubmit}
              className="space-y-3 md:space-y-5 text-black py-2"
            >
              <div className="space-y-3 md:space-y-4">
                {/* Dynamically render form fields to make it easy to adjust and maintain */}
                {[
                  { id: "cardNumber", label: "Card Number", type: "text" },
                  {
                    id: "cardHolderName",
                    label: "Cardholder's Name",
                    type: "text",
                  },
                  {
                    id: "phoneNumber",
                    label: "Cardholder's Phone Number",
                    type: "tel",
                  },
                  { id: "addressLine1", label: "Address Line 1", type: "text" },
                  {
                    id: "addressLine2",
                    label: "Address Line 2",
                    type: "text",
                    required: false,
                  },
                  { id: "city", label: "City", type: "text" },
                ].map(({ id, label, type, required = true }) => (
                  <div key={id}>
                    <label htmlFor={id} className="block text-gray-400 mb-2">
                      {label}
                    </label>
                    <InputField
                      type={type}
                      id={id}
                      name={id}
                      onChange={handleInputChange}
                      required={required}
                      className="w-full p-2 rounded-md" // Ensure full width and padding for touch targets
                    />
                  </div>
                ))}
                <div className="flex gap-2">
                  {/* Similar adjustments for expiry month/year and CVV */}
                  <InputField
                    type="text"
                    id="expiryMonth"
                    name="expiryMonth"
                    placeholder="MM"
                    onChange={handleInputChange}
                    required={true}
                    className="w-1/2 p-2 rounded-md"
                  />
                  <InputField
                    type="text"
                    id="expiryYear"
                    name="expiryYear"
                    placeholder="YY"
                    onChange={handleInputChange}
                    required={true}
                    className="w-1/2 p-2 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="cvv" className="block text-gray-400 mb-2">
                    CVV
                  </label>
                  <InputField
                    type="text"
                    id="cvv"
                    name="cvv"
                    onChange={handleInputChange}
                    required={true}
                    className="w-full p-2 rounded-md"
                  />
                </div>
              </div>
              {/* Improve button responsiveness and accessibility */}
              <button
                type="submit"
                className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 md:py-3 rounded-lg transition-colors duration-200 ease-in-out"
              >
                Confirm Payment
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
