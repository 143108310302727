/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./utility/AuthProvider";
import ApiEndPoints from "../components/api/ApiEndPoints";
import InputField from "./utility/InputField";

export default function Search({
  searchQuery,
  setSearchQuery,
  showSearchBar,
  setShowSearchBar,
  movieList,
}) {
  const node = useRef();
  const [filteredMovies, setFilteredMovies] = useState([]);
  const { setSelectedMovie } = useAuth();
  const navigate = useNavigate();
  const endPoints = ApiEndPoints();

  useEffect(() => {
    if (searchQuery) {
      const filtered = movieList.filter((movie) =>
        movie.Content.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredMovies(filtered);
    } else {
      setFilteredMovies([]);
    }
  }, [searchQuery, movieList]);

  const handleClickOutside = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setShowSearchBar(false);
    }
  };

  var viewSelectedMovie = (content_id) => {
    fetch(endPoints.fetchContentById + content_id)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSelectedMovie(data);
        setShowSearchBar(!showSearchBar);
        navigate("/movie-player");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (showSearchBar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [showSearchBar]);

  return (
    <div className="relative md:flex justify-center items-center text-center">
      <div className="bg-gray-900 text-white rounded-lg px-4 py-1 flex items-center shadow-lg">
        <InputField
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search Movies"
        />
        <button
          className="ml-2 text-white hover:text-gray-500 transition duration-150 ease-in-out"
          onClick={() => setShowSearchBar(!showSearchBar)}
        >
          <AiOutlineSearch className="w-5 h-5 cursor-pointer" />
        </button>
      </div>
      {showSearchBar && filteredMovies.length > 0 && (
        <div className="absolute top-full mt-1 w-full bg-white rounded-lg shadow-lg z-10 divide-y divide-gray-200 overflow-hidden">
          {filteredMovies.map((movie, index) => (
            <div
              key={index}
              className="flex items-center p-2 hover:bg-gray-100 transition duration-150 ease-in-out"
              onClick={() => viewSelectedMovie(movie.Content.content_id)}
            >
              <div className="w-12 h-12 mr-3 flex-shrink-0">
                <img
                  src={movie.imageUrls[0]}
                  alt={movie.Content.title}
                  className="w-full h-full object-cover rounded-md"
                />
              </div>
              <div className="flex-grow">
                <h2 className="font-semibold text-gray-800 text-sm">
                  {movie.Content.title}
                </h2>
                <p className="text-xs text-gray-500">
                  {movie.Content.release_date}
                </p>
                <p className="text-xs text-gray-500">
                  {movie.Content.duration}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
