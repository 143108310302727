import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./utility/AuthProvider";

const SystemAlerts = () => {
  const navigate = useNavigate();

  const { setUserId, setIsUserSignedIn, setUserDetails } = useAuth();

  const inActiveAccountAlert = (email) => {
    Swal.fire({
      title: "Account Inactive",
      text: "Please activate your account to continue using our services.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activate Account",
    }).then((result) => {
      if (result.isConfirmed) {
        setUserDetails(email);
        navigate("/card-details");
      }
    });
  };

  const failedUserLogin = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Incorrect Credientials Please Try Again",
    });
  };

  const validationError = (message) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: message,
    });
  };

  const userCreatedAlert = () => {
    Swal.fire({
      title: "Account Created",
      text: "Please Proceed To Payment.",
      icon: "success",
    });
  };

  const freeTrailUserCreatedAlert = () => {
    Swal.fire({
      title: "Account Created",
      text: "1 Month Free Trail Activated. Please Proceed To Login.",
      icon: "success",
    });
  };

  const failedUserCreationAlert = () => {
    Swal.fire({
      title: "Account Creation Fail",
      text: "Unsuccessful Registration, Please Try Again...",
      icon: "error",
    });
  };

  const successfulPaymentAlert = () => {
    Swal.fire({
      title: "Payment Processed",
      text: "Thank You For Subscribing, Please Proceed To Login.",
      icon: "success",
    });
  };

  const movieAddedToList = (movieTitle) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `${movieTitle} added to your list.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const deleteMovieFromList = (movieTitle) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `${movieTitle} removed from your list.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const passwordChangeSuccess = () => {
    Swal.fire({
      title: "Password Changed!",
      text: "Please Relogin",
      icon: "success",
    });
  };

  return {
    failedUserCreationAlert,
    successfulPaymentAlert,
    inActiveAccountAlert,
    failedUserLogin,
    userCreatedAlert,
    movieAddedToList,
    deleteMovieFromList,
    validationError,
    freeTrailUserCreatedAlert,
    passwordChangeSuccess,
  };
};

export default SystemAlerts;
