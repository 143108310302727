import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./utility/AuthProvider";

export default function ProtectedRoute({ children }) {
  const { userId } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId === null) {
      navigate("/login", { replace: true });
    }
  }, [navigate, userId]);

  return <>{children}</>;
}
