import React from "react";
import banner from "../assets/banner.png";
import { useAuth } from "../components/utility/AuthProvider";

const Footer = () => {
  const { hideFooter } = useAuth();
  return (
    <footer
      className={
        hideFooter ? "hidden" : "bg-black text-white p-6 text-center mt-auto"
      }
    >
      <div className="container mx-auto flex flex-col items-center space-y-4">
        <div>
          <img
            src={banner}
            width={200}
            height={200}
            alt="WATCHDAT"
            className="mx-auto object-contain h-20"
          />
        </div>
        <div className="space-x-6 text-center">
          {/* <a href="/" className="text-gray-400 hover:text-white">
            About
          </a>
          <a href="/" className="text-gray-400 hover:text-white">
            Contact
          </a>
          <a href="/" className="text-gray-400 hover:text-white">
            Terms of Service
          </a>
          <a href="/" className="text-gray-400 hover:text-white">
            Contact
          </a> */}
        </div>
        <div className="text-sm text-gray-400">
          &copy; {new Date().getFullYear()} WATCHDAT. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
