import { RiUser3Line, RiMailLine, RiPhoneLine } from "react-icons/ri";
import React, { useState, useEffect } from "react";
import hero from "../assets/hero.jpg";
import ApiEndPoints from "../components/api/ApiEndPoints";
import { useAuth } from "../components/utility/AuthProvider";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

const Account = ({ setLoggedIn }) => {
  const [accountDetails, setAccountDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const endPoints = ApiEndPoints();

  const {
    hideNavbar,
    hideFooter,
    setHideNavbar,
    setHideFooter,
    setIsUserSignedIn,
    setUserId,
  } = useAuth();

  const navigate = useNavigate();

  var fetchAccountDetails = () => {
    fetch(endPoints.fetchUserData)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAccountDetails(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const deleteAccount = () => {
    fetch(endPoints.postDeleteAccount)
      .then((response) => response.json())
      .then((data) => {
        // Perform account deletion logic here
        console.log("Account Deleted");
        setIsOpen(false);
        window.localStorage.removeItem("isLoggedIn");
        window.localStorage.removeItem("userId");
        setIsUserSignedIn(false);
        setUserId(null);
        setLoggedIn(false);
        navigate("/login", { replace: true });
      });
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  useEffect(() => {
    console.clear();
    if (hideNavbar || hideFooter) {
      setHideNavbar(false);
      setHideFooter(false);
    }
  }, []);

  return (
    <>
      <div className="w-full text-white">
        <img className="w-full h-[300px] object-cover" src={hero} alt="/" />
        <div className="bg-black/60 fixed top-0 left-0 w-full h-[300px]"></div>
        <div className="absolute top-[20%] p-4 md:p-8">
          <h1 className="text-3xl md:text-5xl font-bold">My Account</h1>
        </div>
        <div className="p-4 md:p-8 bg-black text-white">
          <div className="flex items-center mb-4">
            <RiUser3Line className="h-6 w-6 mr-2" />
            <h2 className="text-xl font-bold">{accountDetails.name}</h2>
          </div>
          <div className="flex items-center mb-4">
            <RiMailLine className="h-6 w-6 mr-2" />
            <h2 className="text-xl">{accountDetails.email}</h2>
          </div>
          <div className="flex items-center mb-4">
            <RiPhoneLine className="h-6 w-6 mr-2" />
            <h2 className="text-xl">{accountDetails.phoneNumber}</h2>
          </div>
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            onClick={() => setIsOpen(true)}
          >
            Delete Account
          </button>
        </div>
      </div>

      <Transition show={isOpen} as={React.Fragment}>
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />
            <div className="relative bg-gray-800 rounded-lg shadow-lg mx-auto p-6 max-w-md w-full">
              <Dialog.Title
                as="h3"
                className="text-2xl leading-6 font-semibold text-white mb-4"
              >
                Confirm Account Deletion
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-300">
                  Are you sure you want to delete your account? This action
                  cannot be undone and all your data will be permanently
                  removed.
                </p>
              </div>
              <div className="mt-6">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-red-600"
                    checked={isConfirmed}
                    onChange={() => setIsConfirmed(!isConfirmed)}
                  />
                  <span className="ml-2 text-sm text-gray-300">
                    I understand the consequences and want to delete my account
                  </span>
                </label>
                <button
                  type="button"
                  className={`mt-4 w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500 ${
                    isConfirmed
                      ? "opacity-100"
                      : "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={deleteAccount}
                  disabled={!isConfirmed}
                >
                  Delete Account
                </button>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-300 bg-gray-700 border border-transparent rounded-md hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Account;
