import React, { useState, useEffect } from "react";
import SavedShows from "../components/SavedShows";
import hero from "../assets/hero.jpg";
import ApiEndPoints from "../components/api/ApiEndPoints";
import { useAuth } from "../components/utility/AuthProvider";

const MyList = () => {
  const [savedMovies, setSavedMovies] = useState([]);
  const endPoints = ApiEndPoints();

  const { hideNavbar, hideFooter, setHideNavbar, setHideFooter } = useAuth();

  var fetchSavedMovies = () => {
    fetch(endPoints.fetchUserSavedList)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSavedMovies(data.content_details);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    console.clear();
    if (hideNavbar || hideFooter) {
      setHideNavbar(false);
      setHideFooter(false);
    }
  }, []);

  useEffect(() => {
    fetchSavedMovies();
  }, [savedMovies]);

  return (
    <>
      <div className="w-full min-h-screen text-white">
        <img className="w-full h-[300px] object-cover" src={hero} alt="/" />
        <div className="bg-black/60 fixed top-0 left-0 w-full h-[300px]"></div>
        <div className="absolute top-[20%] p-4 md:p-8">
          <h1 className="text-3xl md:text-5xl font-bold">My List</h1>
        </div>
        <SavedShows
          savedMovies={savedMovies}
          refetchSavedMovies={fetchSavedMovies}
          endPoints={endPoints}
        />
      </div>
    </>
  );
};

export default MyList;
