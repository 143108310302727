import { React, useEffect } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import hero from "../assets/hero.jpg";
import unearth from "../assets/unearth.jpeg";
import vibrant from "../assets/vibrant.png";
import travel from "../assets/travel.png";
import { useAuth } from "../components/utility/AuthProvider";

const GetStarted = () => {
  const navigate = useNavigate();

  const { hideNavbar, hideFooter, setHideNavbar, setHideFooter } = useAuth();

  const handleSignUpNav = () => {
    navigate("/signup");
  };

  useEffect(() => {
    console.clear();
    if (hideNavbar || hideFooter) {
      setHideNavbar(false);
      setHideFooter(false);
    }
  }, []);

  return (
    <div
      className="bg-no-repeat bg-fixed bg-cover bg-center text-white"
      style={{
        backgroundImage: `url(${hero})`,
        backdropFilter: "blur(50px)",
      }}
    >
      <div className="bg-black w-full h-full lg:bg-opacity-80">
        <div className="flex flex-col items-center justify-center h-screen px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-2 sm:mb-4">
            Welcome to WatchDat
          </h1>
          <p className="text-base sm:text-lg md:text-2xl mb-4 sm:mb-8">
            Your Gateway to Jamaican Entertainment!
          </p>
          <button
            onClick={handleSignUpNav}
            className="bg-green-800 hover:bg-green-900 text-white text-base sm:text-lg md:text-xl font-bold py-2 px-4 sm:px-6 rounded shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 flex items-center space-x-2"
          >
            <span className="font-semibold">Get Started</span>
            <RiArrowRightSLine />
          </button>
        </div>
        <div className="bg-black px-4 sm:px-6 lg:px-8">
          <Section
            index={0}
            title="Unearth New Favorites"
            description="Discover Caribbean Movie Gems"
            imgSrc={unearth}
          />
          <hr className="border-t-4 border-gray-700" />
          <Section
            index={1}
            title="Vibrant Caribbean Cinema"
            description="Rich Culture, Unique Storytelling"
            imgSrc={vibrant}
          />
          <hr className="border-t-4 border-gray-700" />
          <Section
            index={2}
            title="Travel With Diverse Movies"
            description="Explore Caribbean Through Film"
            imgSrc={travel}
          />
          <hr className="border-t-4 border-gray-700" />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;

const Section = ({ title, description, imgSrc, index }) => (
  <div
    className={`flex p-4 md:p-8 ${
      index % 2 === 0 ? "flex-row" : "flex-row-reverse"
    }`}
  >
    <div className="w-1/2 flex items-center justify-center">
      <img src={imgSrc ? imgSrc : "placeholder-image.png"} alt="Section" />
    </div>

    <div className="w-1/2 flex flex-col items-center justify-center p-4 md:p-8">
      <h2 className="text-2xl md:text-4xl font-bold mb-2">{title}</h2>
      <p className="text-lg md:text-xl">{description}</p>
    </div>
  </div>
);
