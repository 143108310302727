import { React, useState, useEffect } from "react";
import hero from "../assets/hero.jpg";
import ApiEndPoints from "../components/api/ApiEndPoints";
import GenreMovie from "../components/movie/GenreMovie";
import { useAuth } from "../components/utility/AuthProvider";

const Explore = () => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const endPoints = ApiEndPoints();

  const [genreResults, setGenreResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [genreOptions, setGenreOptions] = useState({});
  const { hideNavbar, hideFooter, setHideNavbar, setHideFooter } = useAuth();

  const fetchGenres = () => {
    fetch(endPoints.fetchGenres)
      .then((response) => response.json())
      .then((data) => {
        setGenreOptions(data);
      });
  };

  useEffect(() => {
    console.clear();
    if (hideNavbar || hideFooter) {
      setHideNavbar(false);
      setHideFooter(false);
    }
  }, []);

  useEffect(() => {
    fetchGenres();
  }, []);

  return (
    <>
      <div className="w-full min-h-screen text-white">
        <img className="w-full h-[300px] object-cover" src={hero} alt="/" />
        <div className="bg-black/60 fixed top-0 left-0 w-full h-[300px]"></div>
        <div className="absolute top-[20%] px-4 md:px-8 flex flex-col md:flex-row items-center w-full">
          <h1 className="text-xl md:text-5xl font-bold text-center mr-4 md:text-left">
            Select A Category
          </h1>
          <div className="relative mt-4 md:mt-0 w-full md:w-auto">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="px-3 py-2 bg-black text-white border-2 border-white rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 transition duration-150 ease-in-out hover:bg-gray-800 w-full md:w-auto"
            >
              Select A Genre
            </button>
            {isDropdownOpen && (
              <div className="absolute top-full mt-2 bg-white text-black rounded-lg shadow-lg p-2 w-full md:w-64 grid grid-cols-2 gap-2 z-10 left-0 md:left-auto">
                {genreOptions.map((genre) => (
                  <CategoryButton
                    key={genre.genre_id}
                    genre={genre.title}
                    selectedGenre={selectedGenre}
                    setSelectedGenre={setSelectedGenre}
                    endPoints={endPoints}
                    setGenreResults={setGenreResults}
                    setIsDropdownOpen={setIsDropdownOpen}
                    genreOptions={genreOptions}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="relative">
          <div
            className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 p-4 ${
              genreResults.length === 0 ? "hidden" : ""
            }`}
          >
            {genreResults.map((item) => (
              <GenreMovie item={item} key={item.id} />
            ))}
          </div>
          {genreResults.length === 0 && (
            <div className="flex justify-center items-center h-full mt-3 p-3">
              <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-400 text-center">
                Please Explore a Category.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Explore;

const CategoryButton = ({
  genre,
  selectedGenre,
  setSelectedGenre,
  endPoints,
  setGenreResults,
  setIsDropdownOpen,
  genreOptions,
}) => {
  const isSelected = selectedGenre === genre;

  // const getGenreFromKey = (genreId) => {
  //   return genreReference[genreId] || "";
  // };

  const getKeyFromGenre = (genre) => {
    const foundGenre = genreOptions.find((g) => g.title === genre);
    return foundGenre ? foundGenre.genre_id : null;
  };

  const handleClick = async () => {
    fetch(endPoints.fetchAllMovieByCategory + getKeyFromGenre(genre))
      .then((response) => response.json())
      .then((data) => {
        setGenreResults(data.contents);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setSelectedGenre(isSelected ? null : genre);
    setIsDropdownOpen(false);
  };

  return (
    <div
      onClick={handleClick}
      className={`m-2 px-3 md:px-4 py-1 md:py-2 border ${
        isSelected
          ? "bg-blue-600 text-white shadow-lg"
          : "bg-white text-gray-700 hover:bg-gray-100"
      } rounded-lg text-xs md:text-sm font-medium cursor-pointer transition-all duration-200 ease-in-out flex items-center justify-center`}
      style={{ minWidth: "4rem", minHeight: "2rem" }}
    >
      {genre}
    </div>
  );
};
