/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import hero from "../assets/hero.jpg";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useAuth } from "../components/utility/AuthProvider";
import ApiEndPoints from "../components/api/ApiEndPoints";
import InputField from "../components/utility/InputField";
import SystemAlerts from "../components/SystemAlerts";
import InActiveAccountDialog from "../components/utility/InactiveDialog";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showInactiveDialog, setShowInactiveDialog] = useState(false);
  const {
    hideNavbar,
    hideFooter,
    setHideNavbar,
    setHideFooter,
    setUserId,
    setIsUserSignedIn,
    setChosenPackageType,
    setUserDetails,
  } = useAuth();

  const navigate = useNavigate();
  const endPoints = ApiEndPoints();
  const systemAlerts = SystemAlerts();

  const postUserLogin = async () => {
    try {
      const response = await fetch(endPoints.loginUser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, password: password }),
      });
      const data = await response.json();

      if (data.status === 200) {
        window.localStorage.setItem("userId", data.user_id);
        window.localStorage.setItem("isLoggedIn", true);
        setUserId(data.user_id);
        setIsUserSignedIn(true);
        navigate("/home");
      } else if (data.status === 403) {
        // systemAlerts.inActiveAccountAlert(email);
        setShowInactiveDialog(true);
      } else {
        systemAlerts.failedUserLogin();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSignUpNav = () => {
    navigate("/signup");
  };

  // Refactored handleSubmit to use async/await
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postUserLogin(); // Ensures login is attempted before proceeding
    } catch (error) {
      console.error("Error:", error);
      systemAlerts.validationError(error);
    }
  };

  useEffect(() => {
    console.clear();
    if (hideNavbar || hideFooter) {
      setHideNavbar(false);
      setHideFooter(false);
    }
  }, []);

  useEffect(() => {
    const status = window.localStorage.getItem("isLoggedIn") === "true";
    if (status) {
      navigate("/home");
    }
  }, []);

  return (
    <div
      className="bg-no-repeat bg-fixed bg-cover bg-center text-white w-full h-screen"
      style={{
        backgroundImage: `url(${hero})`,
        backdropFilter: "blur(50px)",
      }}
    >
      <div className="bg-black/80 w-full h-full flex justify-center items-center px-4">
        <div className="w-full max-w-md px-6 py-8 bg-black/75 text-white rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold mb-8 text-center">Sign In</h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            <fieldset className="flex flex-col space-y-4">
              <InputField
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Email"
                autoComplete="email"
              />
              <div className="relative">
                <InputField
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="current-password"
                  required={true}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>
            </fieldset>
            <button
              type="submit"
              className="w-full py-3 rounded bg-gradient-to-r from-green-600 to-green-900 hover:from-green-600 hover:to-green-800 transition-all text-white font-bold"
            >
              Sign In
            </button>
            <p className="py-2 text-center">
              <span className="text-gray-400">Don't have an account?</span>
              <span
                onClick={handleSignUpNav}
                className="text-white hover:text-green-500 cursor-pointer transition-colors ml-2"
              >
                Create Your Account
              </span>
            </p>
          </form>
          <InActiveAccountDialog
            accountEmail={email}
            open={showInactiveDialog}
            onClose={() => setShowInactiveDialog(false)}
            setChosenPackageType={setChosenPackageType}
            setUserDetails={setUserDetails}
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
