const InputField = ({
  id = "",
  onChange,
  name,
  type,
  placeholder,
  autoComplete,
  required = false,
  value,
  readOnly = false,
}) => {
  return (
    <div className="relative">
      <input
        id={id}
        name={name}
        onChange={onChange}
        className="p-3 w-full bg-gray-700 rounded opacity-50 focus:opacity-100 focus:outline-green-500 text-white"
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        required={required}
        value={value}
        readOnly={readOnly}
      />
    </div>
  );
};

export default InputField;
