import React, { useState } from "react";
import { IoIosCloseCircle, IoIosEye, IoIosEyeOff } from "react-icons/io";
import { RiLockPasswordLine } from "react-icons/ri";
import { useAuth } from "./utility/AuthProvider";
import { useNavigate } from "react-router-dom";
import SystemAlerts from "./SystemAlerts";
import ApiEndPoints from "./api/ApiEndPoints";

const ChangePasswordDialog = ({
  isDialogVisible,
  setIsDialogVisible,
  setLoggedIn,
}) => {
  const endPoints = ApiEndPoints();
  const navigate = useNavigate();
  const systemAlerts = SystemAlerts();
  const { setIsUserSignedIn, setUserId } = useAuth();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangePassword = async () => {
    if (
      currentPassword === newPassword ||
      currentPassword === confirmPassword
    ) {
      setError("You can't change your password to the same password.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    } else {
      try {
        const response = await fetch(endPoints.changeUserPassword, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
          }),
        });

        const data = await response.json();

        // TODO: CONTINUE FROM HERE, CHANGE PASSWORD THEN DELETE ACCOUNT

        if (data.status === 200) {
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");

          setIsDialogVisible(false);

          setShowCurrentPassword(false);
          setShowNewPassword(false);
          setShowConfirmPassword(false);

          console.log(data);

          setTimeout(() => {
            window.localStorage.removeItem("isLoggedIn");
            window.localStorage.removeItem("userId");
            setIsUserSignedIn(false);
            setUserId(null);
            setLoggedIn(false);
            navigate("/login", { replace: true });
            systemAlerts.passwordChangeSuccess();
          }, 300);
        } else {
          setError("Unable Changing Password");
        }
      } catch (error) {}
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsDialogVisible(false);
    }
  };

  return (
    isDialogVisible && (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-80 transition duration-300"
        onClick={handleOverlayClick}
      >
        <div className="relative w-11/12 max-w-md mx-auto overflow-hidden rounded-md">
          <div
            className={`${
              isDialogVisible ? "scale-100" : "scale-0"
            } transform duration-300 relative flex-auto bg-zinc-900 drop-shadow-md overflow-y-auto`}
          >
            <div
              onClick={() => setIsDialogVisible(false)}
              className="absolute top-4 right-4 flex items-center justify-center w-8 h-8 text-white rounded-full bg-black bg-opacity-70 hover:cursor-pointer"
            >
              <IoIosCloseCircle className="text-2xl" />
            </div>

            <div className="p-8">
              <h2 className="mb-6 text-2xl font-bold text-white">
                Change Password
              </h2>
              <div className="mb-4">
                <label
                  htmlFor="currentPassword"
                  className="block mb-2 text-sm font-semibold text-white"
                >
                  Current Password
                </label>
                <div className="relative">
                  <input
                    type={showCurrentPassword ? "text" : "password"}
                    id="currentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="w-full px-4 py-2 pr-10 text-white rounded-md bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  >
                    {showCurrentPassword ? (
                      <IoIosEyeOff className="text-white" />
                    ) : (
                      <IoIosEye className="text-white" />
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="newPassword"
                  className="block mb-2 text-sm font-semibold text-white"
                >
                  New Password
                </label>
                <div className="relative">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 pr-10 text-white rounded-md bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <IoIosEyeOff className="text-white" />
                    ) : (
                      <IoIosEye className="text-white" />
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="confirmPassword"
                  className="block mb-2 text-sm font-semibold text-white"
                >
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 pr-10 text-white rounded-md bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <IoIosEyeOff className="text-white" />
                    ) : (
                      <IoIosEye className="text-white" />
                    )}
                  </div>
                </div>
              </div>
              {error && <p className="mb-4 text-sm text-red-500">{error}</p>}
              <div className="flex justify-center">
                <button
                  onClick={handleChangePassword}
                  className="flex items-center px-6 py-2 text-white rounded-md bg-blue-500 hover:bg-blue-600 transition duration-300"
                >
                  <RiLockPasswordLine className="mr-2 text-lg" />
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ChangePasswordDialog;
