/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useAuth } from "../components/utility/AuthProvider";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import ApiEndPoints from "./api/ApiEndPoints";

function VideoPlayer({ videoUrl, title, contentId }) {
  const endPoints = ApiEndPoints();
  const navigate = useNavigate();

  const videoRef = useRef(null);

  const { setHideFooter, setHideNavbar, hideNavbar, hideFooter } = useAuth();

  const [showControls, setShowControls] = useState(false); // Start with hidden controls
  const [isPaused, setIsPaused] = useState(false);

  const [watchedPercentage, setWatchedPercentage] = useState(0);
  const [contentCredited, setContentCredited] = useState(false);

  const timeToHide = 5000;
  let movementTimer = null;

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const creditContent = () => {
    fetch(endPoints.postUserViewedContent(contentId))
      .then((response) => response.json())
      .then((data) => {});
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const percentage = (currentTime / duration) * 100;
    setWatchedPercentage(percentage);

    if (percentage >= 70 && !contentCredited) {
      creditContent();
      setContentCredited(true);
    }
  };

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (!hideNavbar || !hideFooter) {
      setHideNavbar(true);
      setHideFooter(true);
    }
  }, []);

  const handleMouseMove = () => {
    if (movementTimer !== null) {
      clearTimeout(movementTimer);
    }
    setShowControls(true); // Show controls when mouse moves
    movementTimer = setTimeout(() => {
      if (!isPaused) {
        setShowControls(false); // Hide controls after a delay
      }
      movementTimer = null;
    }, timeToHide);
  };

  const handlePause = () => {
    setIsPaused(true);
    setShowControls(true);
  };

  const handlePlay = () => {
    setIsPaused(false);
    setShowControls(false);
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div
      className="w-full h-screen relative overflow-hidden bg-black"
      onMouseMove={handleMouseMove}
    >
      <video
        ref={videoRef}
        className="absolute inset-0 w-full h-full object-cover"
        src={videoUrl}
        controls={showControls}
        autoPlay={true}
        muted={false}
        controlsList="nodownload"
        onPause={handlePause}
        onPlay={handlePlay}
        onTimeUpdate={handleTimeUpdate}
        onContextMenu={handleContextMenu}
      />
      {showControls && (
        <div className="absolute top-0 left-0 mt-4 ml-4 flex items-center">
          <button
            className="text-white text-2xl focus:outline-none mr-4"
            onClick={handleBackClick}
          >
            <FaArrowLeft />
          </button>
          <p className="text-white text-lg">{title}</p>
        </div>
      )}
    </div>
  );
}

export default VideoPlayer;
