import { React, useState } from "react";
import { RiPlayListAddFill } from "react-icons/ri";
import { useAuth } from "../utility/AuthProvider";
import ApiEndPoints from "../api/ApiEndPoints";
import MovieDialog from "../MovieDialog";
import SystemAlerts from "../SystemAlerts";

const GenreMovie = ({ item }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [movieDetails, setMovieDetails] = useState(null);

  const { setSelectedContent } = useAuth();
  const endPoints = ApiEndPoints();
  const systemAlerts = SystemAlerts();

  const fetchMovieDetails = () => {
    fetch(endPoints.fetchContentById + item.Content.content_id)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSelectedContent({
          contentUrl: data.videoUrls[0],
          contentTitle: data.Content.title,
          contentId: data.Content.content_id,
        });
        setMovieDetails(data);
        setIsDialogVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var postAddToMyList = () => {
    fetch(endPoints.saveUserMovieList + item.Content.content_id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          systemAlerts.movieAddedToList(item?.Content.title);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div
        className="relative w-full h-48 cursor-pointer overflow-hidden rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105"
        onClick={fetchMovieDetails}
      >
        <img
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={`${item?.imageUrls[0]}`}
          alt={`${item?.Content.title}`}
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex flex-col justify-center items-center text-white p-2">
          <p className="text-sm font-bold text-center mb-2">
            {item?.Content.title}
          </p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              postAddToMyList();
            }}
            className="absolute top-2 left-2 p-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-300 ease-in-out"
          >
            <RiPlayListAddFill size={20} />
          </button>
        </div>
      </div>
      <MovieDialog
        isDialogVisible={isDialogVisible}
        movieDetails={movieDetails}
        setIsDialogVisible={setIsDialogVisible}
      />
    </>
  );
};

export default GenreMovie;
