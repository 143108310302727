import { React, useState } from "react";
import { RiPlayListAddFill } from "react-icons/ri";
import { useAuth } from "../utility/AuthProvider";
import ApiEndPoints from "../api/ApiEndPoints";
import MovieDialog from "../MovieDialog";
import SystemAlerts from "../SystemAlerts";

const NewReleaseMovie = ({ item }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [movieDetails, setMovieDetails] = useState(null);
  const { setSelectedContent } = useAuth();
  const endPoints = ApiEndPoints();
  const systemAlerts = SystemAlerts();

  const fetchMovieDetails = () => {
    fetch(endPoints.fetchContentById + item.content_id)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSelectedContent({
          contentUrl: data.videoUrls[0],
          contentTitle: data.Content.title,
          contentId: data.Content.content_id,
        });
        setMovieDetails(data);
        setIsDialogVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var postAddToMyList = () => {
    fetch(endPoints.saveUserMovieList + item.content_id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          systemAlerts.movieAddedToList(item?.title);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div
        className="w-64 h-48 inline-block cursor-pointer relative p-2"
        onClick={fetchMovieDetails}
      >
        <img
          className="w-full h-full object-cover block"
          src={`${item?.imageUrls[0]}`}
          alt={`${item?.title}`}
        />
        <div className="absolute top-0 left-0 w-full h-full hover:bg-black/80 opacity-0 hover:opacity-100 text-white flex flex-col justify-center items-center">
          <p className="white-space-normal text-xs md:text-sm font-bold text-center">
            {item?.title}
          </p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              postAddToMyList();
            }}
            className="absolute text-gray-300 top-4 left-4 p-2 hover:bg-blue-500 hover:text-white rounded-full"
          >
            <RiPlayListAddFill />
          </button>
        </div>
      </div>

      <MovieDialog
        isDialogVisible={isDialogVisible}
        movieDetails={movieDetails}
        setIsDialogVisible={setIsDialogVisible}
      />
    </>
  );
};

export default NewReleaseMovie;
