import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import React, { useEffect, useState } from "react";
import NewReleaseMovie from "./movie/NewReleaseMovie";
import RecentlyWatchMovie from "./movie/RecentlyWatchMovie";
import TopFive from "./movie/TopFive";
import EditorsPick from "./movie/EditorsPick";

const Row = ({ rowID, title, movieList }) => {
  const [movies, setMovies] = useState([]);

  const movieComponentMapping = {
    1: NewReleaseMovie,
    2: RecentlyWatchMovie,
    3: TopFive,
    4: EditorsPick,
  };

  useEffect(() => {
    if (movieList !== null) {
      setMovies(movieList);
    }
  }, [movieList]);

  const slideLeft = () => {
    var slider = document.getElementById("grid" + `${rowID}`);
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById("grid" + `${rowID}`);
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <div className="px-4 md:px-12 mt-4 space-y-8">
      <div className="relative">
        <p className="text-white text-md md:text-xl lg:text-2xl font-semibold mb-4">
          {title}
        </p>
        <div className="flex items-center group">
          <RiArrowLeftSLine
            onClick={slideLeft}
            className="bg-green-500 left-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block"
            style={{ top: "50%", transform: "translateY(-50%)" }}
            size={40}
          />
          <div
            id={"grid" + `${rowID}`}
            className="grid grid-flow-col gap-4 overflow-x-scroll scrollbar-hide"
          >
            {movies.map((item, id) => {
              const MovieComponent = movieComponentMapping[rowID];
              return <MovieComponent key={id} item={item} />;
            })}
          </div>
          <RiArrowRightSLine
            onClick={slideRight}
            className="bg-green-500 right-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block"
            style={{ top: "50%", transform: "translateY(-50%)" }}
            size={40}
          />
        </div>
      </div>
    </div>
  );
};

export default Row;
