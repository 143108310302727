import { useAuth } from "../utility/AuthProvider";

const ApiEndPoints = () => {
  const { userId } = useAuth();
  // const endPointUrl = "http://www.watchdat.com";
  const endPointUrl = "http://18.218.59.136";
  // const endPointUrl = "";

  const fetchContentById = `${endPointUrl}/WatchDeh_api/web/v1/content/content?Id=`;
  const fetchAllContent = `${endPointUrl}/WatchDeh_api/web/v1/content/get-content`;
  const fetchUserProfile = `${endPointUrl}/WatchDeh_api/web/v1/user/profile?userId=${userId}`;
  const loginUser = `${endPointUrl}/WatchDeh_api/web/v1/user/login`;
  const changeUserPassword = `${endPointUrl}/WatchDeh_api/web/v1/user/change-password?id=${userId}`;
  const fetchUserSavedList = `${endPointUrl}/WatchDeh_api/web/v1/content/get-saved?user_id=${userId}`;
  const saveUserMovieList = `${endPointUrl}/WatchDeh_api/web/v1/content/save?user_id=${userId}&content_id=`;
  const removeMovieFromUserList = `${endPointUrl}/WatchDeh_api/web/v1/content/delete-saved?user_id=${userId}&content_id=`;
  const updateUserWatchedMovies = `${endPointUrl}/WatchDeh_api/web/v1/content/add-to-watched?user_id=${userId}&content_id=`;
  const fetchUserWatchedMovies = `${endPointUrl}/WatchDeh_api/web/v1/content/get-watched?user_id=${userId}`;
  const fetchRecentlyAddedContent = `${endPointUrl}/WatchDeh_api/web/v1/content/just-added`;
  const fetchAllMovieByCategory = `${endPointUrl}/WatchDeh_api/web/v1/content/search-categories?category=`;
  const postUserLogin = `${endPointUrl}/WatchDeh_api/web/v1/user/login`;
  const postCreateUser = `${endPointUrl}/WatchDeh_api/web/v1/user/create-user`;
  const postCreateUserFreeTrail = `${endPointUrl}/WatchDeh_api/web/v1/user/create-trial-user`;
  const postUpdateUserPassword = `${endPointUrl}/WatchDeh_api/web/v1/user/change-password?id=${userId}`;
  const fetchUserData = `${endPointUrl}/WatchDeh_api/web/v1/user/profile?userId=${userId}`;
  const postInitiatePayment = `${endPointUrl}/WatchDeh_api/web/v1/payment/initiate-payment`;
  const postCompletePayment = `${endPointUrl}/WatchDeh_api/web/v1/payment/complete-payment`;
  const fetchAccountData = `${endPointUrl}/WatchDeh_api/web/v1/user/view-user?id=${userId}`;
  const fetchSeriesContent = `${endPointUrl}/WatchDeh_api/web/v1/content/get-seasons-and-episodes?content_id=`;
  const postUserViewedContent = (content_id) => {
    return `${endPointUrl}/WatchDeh_api/web/v1/content/content-views?content_id=${content_id}&user_id=${userId}`;
  };
  const fetchGenres = `${endPointUrl}/WatchDeh_api/web/v1/content/get-categories`;
  const postDeleteAccount = `${endPointUrl}/WatchDeh_api/web/v1/user/delete-user?id=${userId}`;

  return {
    fetchContentById,
    fetchAllContent,
    fetchUserProfile,
    loginUser,
    changeUserPassword,
    fetchUserSavedList,
    saveUserMovieList,
    removeMovieFromUserList,
    updateUserWatchedMovies,
    fetchUserWatchedMovies,
    fetchRecentlyAddedContent,
    fetchAllMovieByCategory,
    postUserLogin,
    postCreateUser,
    postCreateUserFreeTrail,
    postUpdateUserPassword,
    fetchUserData,
    postInitiatePayment,
    postCompletePayment,
    fetchAccountData,
    fetchSeriesContent,
    postUserViewedContent,
    fetchGenres,
    postDeleteAccount,
  };
};

export default ApiEndPoints;
