import { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [userId, setUserId] = useState(null);
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [hideNavbar, setHideNavbar] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [selectedContent, setSelectedContent] = useState({
    contentUrl: "",
    contentTitle: "",
    contentId: "",
  });
  const [searchMovieList, setSearchMovieList] = useState([]);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [isMoviePlaying, setIsMoviePlaying] = useState(false);
  const [chosenPackageType, setChosenPackageType] = useState(0);

  useEffect(() => {
    const loggedIn = window.localStorage.getItem("isLoggedIn") === "true";
    const userId = window.localStorage.getItem("userId");
    setIsUserSignedIn(loggedIn);
    setUserId(userId);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userId,
        setUserId,
        isUserSignedIn,
        setIsUserSignedIn,
        isMoviePlaying,
        setIsMoviePlaying,
        hideNavbar,
        setHideNavbar,
        hideFooter,
        setHideFooter,
        userDetails,
        setUserDetails,
        searchMovieList,
        setSearchMovieList,
        nextPaymentDate,
        setNextPaymentDate,
        chosenPackageType,
        setChosenPackageType,
        selectedContent,
        setSelectedContent,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
