/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthProvider from "./components/utility/AuthProvider";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import PaymentDetails from "./pages/PaymentDetails";
import GetStarted from "./pages/GetStarted";
import Explore from "./pages/Explore";
import Account from "./pages/Account";
import MyList from "./pages/MyList";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Home from "./pages/Home";
import MovieScreen from "./pages/MovieScreen";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const status = window.localStorage.getItem("isLoggedIn") === "true";
    if (status) {
      setLoggedIn(status);
      navigate("/home");
    }
  }, []);

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <AuthProvider>
          <Navbar setLoggedIn={setLoggedIn} />
          <div className="flex-grow overflow-auto">
            <Routes>
              <Route path="/" element={loggedIn ? <Home /> : <GetStarted />} />
              <Route path="/login" element={loggedIn ? <Home /> : <Login />} />
              <Route
                path="/signup"
                element={loggedIn ? <Home /> : <SignUp />}
              />
              <Route
                path="/card-details"
                element={loggedIn ? <Home /> : <PaymentDetails />}
              />
              <Route
                path="/home"
                element={
                  <ProtectedRoute>
                    <Home setLoggedIn={setLoggedIn} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/explore"
                element={
                  <ProtectedRoute>
                    <Explore />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-list"
                element={
                  <ProtectedRoute>
                    <MyList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <Account setLoggedIn={setLoggedIn} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/movie-player"
                element={
                  <ProtectedRoute>
                    <MovieScreen />
                  </ProtectedRoute>
                }
              />
              {/* Health check endpoint */}
              <Route
                path="/health"
                element={<h3>Hey There!!! The App is Healthy</h3>}
              />
              {/* Fallback for the health endpoint */}
              <Route path="*" element={<navigate to="/health" replace />} />
            </Routes>
          </div>
          <Footer />
        </AuthProvider>
      </div>
    </>
  );
}

export default App;
