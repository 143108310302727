/* eslint-disable react-hooks/exhaustive-deps */
import ApiEndPoints from "../components/api/ApiEndPoints";
import { useAuth } from "../components/utility/AuthProvider";
import SystemAlerts from "../components/SystemAlerts";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../components/Main";
import Row from "../components/Row";

const Home = ({ setLoggedIn }) => {
  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [recentlyWatched, setRecentlyWatched] = useState([]);
  const [randomContent, setRandomContent] = useState([]);

  const navigate = useNavigate();

  const systemAlerts = SystemAlerts();

  const endPoints = ApiEndPoints();
  const {
    userId,
    hideNavbar,
    hideFooter,
    setHideNavbar,
    setHideFooter,
    setIsUserSignedIn,
    setUserId,
    setNextPaymentDate,
  } = useAuth();

  var fetchAllContent = () => {
    fetch(endPoints.fetchAllContent)
      .then((response) => response.json())
      .then((data) => {
        setRandomContent(data.ContentList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var fetchRecentlyAdded = () => {
    fetch(endPoints.fetchRecentlyAddedContent)
      .then((response) => response.json())
      .then((data) => {
        setRecentlyAdded(data.contentList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var fetchAccountData = () => {
    fetch(endPoints.fetchAccountData)
      .then((response) => response.json())
      .then((data) => {
        if (data.active === 0) {
          handleLogout(data.email);
        } else {
          setNextPaymentDate(data.next_payment);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var fetchRecentlyWatched = () => {
    fetch(endPoints.fetchUserWatchedMovies)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setRecentlyWatched(data.watched_content);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  var getFiveRandomContent = () => {
    const shuffledContent = [...randomContent];
    for (let i = shuffledContent.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledContent[i], shuffledContent[j]] = [
        shuffledContent[j],
        shuffledContent[i],
      ];
    }
    return shuffledContent.slice(0, 5);
  };

  const handleLogout = (email) => {
    window.localStorage.removeItem("isLoggedIn");
    window.localStorage.removeItem("userId");
    setIsUserSignedIn(false);
    setUserId(null);
    setLoggedIn(false);
    navigate("/login", { replace: true });
    systemAlerts.inActiveAccountAlert(email);
  };

  useEffect(() => {
    fetchAccountData();
    fetchRecentlyAdded();
    if (randomContent.length === 0) {
      fetchAllContent();
    }
    if (hideNavbar || hideFooter) {
      setHideNavbar(false);
      setHideFooter(false);
    }
  }, []);

  useEffect(() => {
    if (userId !== null) {
      fetchRecentlyWatched();
    }
  }, [userId]);

  return (
    <>
      <Main />
      <Row
        path="new-releases"
        rowID={1}
        title="New Releases"
        movieList={recentlyAdded}
      />
      {recentlyWatched.length > 0 && (
        <Row
          path="recently-watched"
          rowID={2}
          title="Recently Watched"
          movieList={recentlyWatched}
        />
      )}
      <Row
        path="recently-watched"
        rowID={3}
        title="Top 5"
        movieList={getFiveRandomContent()}
      />
      <Row
        path="recently-watched"
        rowID={4}
        title="Editors Pick"
        movieList={getFiveRandomContent()}
      />
    </>
  );
};

export default Home;
