import React, { useEffect, useState, useRef } from "react";
import { FaVolumeMute, FaVolumeUp, FaPlay, FaPause } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import ApiEndPoints from "./api/ApiEndPoints";
import { useNavigate } from "react-router-dom";
import SystemAlerts from "./SystemAlerts";

import { useAuth } from "./utility/AuthProvider";

const MovieDialog = ({ isDialogVisible, movieDetails, setIsDialogVisible }) => {
  const endPoints = ApiEndPoints();
  const navigate = useNavigate();
  const systemAlerts = SystemAlerts();

  const videoRef = useRef(null);

  const [seriesData, setSeriesData] = useState({});
  const [isPlaying, setIsPlaying] = useState(true);
  const [isTrailerMuted, setIsTrailerMuted] = useState(false);

  const { setSelectedContent } = useAuth();

  var postRecentlyWatchedMovie = (content_id) => {
    fetch(endPoints.updateUserWatchedMovies + content_id)
      .then((response) => response.json())
      .then((data) => {});
  };

  var fetchSeriesData = () => {
    if (movieDetails?.Content?.content_id) {
      fetch(endPoints.fetchSeriesContent + movieDetails.Content.content_id)
        .then((response) => response.json())
        .then((data) => {
          console.log("Fetched Series Data.");
          setSeriesData(data.data);
          console.log(data.data);
        });
    }
  };

  var handleAddToList = () => {
    if (movieDetails?.Content?.content_id) {
      fetch(endPoints.saveUserMovieList + movieDetails.Content.content_id)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            systemAlerts.movieAddedToList(movieDetails?.Content?.title || "");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  var handlePlayButton = () => {
    setSelectedContent({
      contentUrl: movieDetails.videoUrls[0],
      contentTitle: movieDetails.Content.title,
      contentId: movieDetails.Content.content_id,
    });
    postRecentlyWatchedMovie();
    navigate("/movie-player");
  };

  const toggleTrailerVolume = () => {
    setIsTrailerMuted(!isTrailerMuted);
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setIsDialogVisible(false);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const getMonthAndYear = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      return `${month}, ${year}`;
    }
    return "";
  };

  const playEpisode = (videoUrls, episodeTitle, contentId) => {
    setSelectedContent({
      contentUrl: videoUrls,
      contentTitle: episodeTitle,
      contentId: contentId,
    });
    postRecentlyWatchedMovie();
    navigate("/movie-player");
  };

  useEffect(() => {
    if (movieDetails?.Content?.genre_id === 15) {
      fetchSeriesData();
    }
  }, [movieDetails]);

  useEffect(() => {
    if (isDialogVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isDialogVisible]);

  return (
    isDialogVisible &&
    movieDetails && (
      <div
        className="z-50 transition duration-300 bg-black bg-opacity-80 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0"
        onClick={handleOverlayClick}
      >
        <div className="relative w-11/12 max-w-3xl mx-auto my-4 rounded-md overflow-hidden">
          <div
            className={`${
              isDialogVisible ? "scale-100" : "scale-0"
            } transform duration-300 relative flex-auto bg-zinc-900 drop-shadow-md h-[80vh] overflow-y-auto`}
          >
            <div className="relative h-96">
              {movieDetails?.trailerUrls?.length > 0 ? (
                <>
                  <video
                    ref={videoRef}
                    src={movieDetails.trailerUrls[0]}
                    autoPlay
                    loop
                    muted={isTrailerMuted}
                    className="w-full brightness-[60%] object-cover h-full"
                    onContextMenu={handleContextMenu}
                  />
                  <button
                    onClick={toggleTrailerVolume}
                    className="absolute bottom-4 right-16 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-80 transition duration-300 shadow-lg"
                  >
                    {isTrailerMuted ? (
                      <FaVolumeMute className="text-white w-6 h-6" />
                    ) : (
                      <FaVolumeUp className="text-white w-6 h-6" />
                    )}
                  </button>
                  <button
                    onClick={handlePlayPause}
                    className="absolute bottom-4 right-4 bg-white bg-opacity-50 rounded-full p-2 hover:bg-opacity-80 transition duration-300 shadow-lg"
                  >
                    {isPlaying ? (
                      <FaPause className="text-white w-6 h-6" />
                    ) : (
                      <FaPlay className="text-white w-6 h-6" />
                    )}
                  </button>
                </>
              ) : (
                <img
                  src={movieDetails?.imageUrls?.[0] ?? ""}
                  alt="/"
                  className="w-full brightness-[60%] object-cover h-full"
                />
              )}
              <div
                onClick={() => setIsDialogVisible(false)}
                className="hover:cursor-pointer absolute top-5 right-5 h-12 w-12 rounded-full text-white bg-black bg-opacity-70 flex items-center justify-center shadow-lg"
              >
                <IoIosCloseCircle className="text-4xl" />
              </div>

              <div className="absolute bottom-[10%] p-4">
                <p className="text-white text-xl md:text-2xl h-full font-bold mb-4 shadow-lg">
                  {movieDetails?.Content?.title || ""}
                </p>
                <div className="flex flex-row gap-4 items-center">
                  {movieDetails?.Content?.genre_id === 15 ? (
                    <></>
                  ) : (
                    <button
                      onClick={handlePlayButton}
                      className="bg-white rounded-md text-black py-2 px-4 text-sm md:text-base font-semibold flex flex-row items-center hover:bg-neutral-300 transition shadow-lg"
                    >
                      <FaPlay className="text-black mr-2" />
                      Play
                    </button>
                  )}

                  <div
                    onClick={handleAddToList}
                    className="cursor-pointer w-8 h-8 md:w-10 md:h-10 border-white border-2 rounded-full flex justify-center items-center transition hover:border-neutral-300 shadow-lg"
                  >
                    <FaCirclePlus className="text-white w-6" />
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 md:px-12 md:py-8">
              <p className="text-green-400 font-semibold text-base md:text-lg">
                {movieDetails?.Content?.original_language || ""}
              </p>
              <div className="flex flex-row items-center gap-2 mb-4">
                <p className="text-white text-sm md:text-base">
                  {getMonthAndYear(movieDetails?.Content?.release_date) || ""}
                </p>
                <p className="text-white text-sm md:text-base">
                  {movieDetails?.Content?.duration || ""}
                </p>
              </div>
              <p className="text-white text-sm md:text-base">
                {movieDetails?.Content?.description || ""}
              </p>
              {seriesData && seriesData.length > 0 && (
                <div className="mt-8">
                  {seriesData.map((seasonData, i) => (
                    <div key={seasonData?.season?.id || i} className="mb-8">
                      <div className="bg-black rounded-md p-4">
                        <div className="text-white text-xl font-bold mb-2">
                          Season {seasonData?.season?.season_number || ""} -{" "}
                          {seasonData?.season?.title || ""}
                        </div>
                        <div className="text-white text-sm mb-4">
                          {getMonthAndYear(seasonData?.season?.release_date) ||
                            ""}
                          <br />
                          {seasonData?.season?.description || ""}
                        </div>
                        <ul className="space-y-4">
                          {seasonData?.episodes?.map((episodeData, j) => (
                            <li
                              key={episodeData?.Episode?.id || j}
                              className="cursor-pointer"
                              onClick={() =>
                                playEpisode(
                                  episodeData?.videoUrls[0] || [],
                                  episodeData?.Episode?.title || "",
                                  movieDetails?.Content?.content_id || ""
                                )
                              }
                            >
                              <div className="flex items-center space-x-4">
                                <div className="w-28 h-16 bg-gray-300 rounded-md flex-shrink-0 overflow-hidden">
                                  <video
                                    src={episodeData?.videoUrls?.[0] || ""}
                                    muted
                                    className="w-full h-full object-cover"
                                    ref={(video) => {
                                      if (video) {
                                        video.currentTime = 10;
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  <div className="text-white font-semibold">
                                    Episode{" "}
                                    {episodeData?.Episode?.episode_number || ""}
                                    : {episodeData?.Episode?.title || ""}
                                  </div>
                                  <div className="text-white text-sm">
                                    {getMonthAndYear(
                                      episodeData?.Episode?.release_date
                                    ) || ""}
                                    <br />
                                    {episodeData?.Episode?.description || ""}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MovieDialog;
